<template>
  <div>
    <h1 class="mb-1">Rede</h1>
    <div v-if="loading" class="d-flex justify-content-center">
      <b-spinner variant="primary" />
    </div>
    <template v-else-if="details">
      <b-table
        hover
        :items="details"
        borderless
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="Não foram encontrados consultores"
        :busy="loading"
        @row-clicked="goToConsultantDetails"
      >
        <!-- Column: installment -->
        <template #cell(id)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.id
            }}</span>
          </div>
        </template>
        <!-- Column: consultant -->
        <template #cell(name)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.name
            }}</span>
          </div>
        </template>
        <!-- Column: role -->
        <template #cell(role_name)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.role_name
            }}</span>
          </div>
        </template>
      </b-table>
    </template>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BAvatar,
  BSpinner,
  BButton,
  BPopover,
  BCardText,
  BTable,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import * as types from "@/modules/consultant/store/types";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
export default {
  components: {
    BRow,
    BCol,
    BAvatar,
    BSpinner,
    BButton,
    BPopover,
    BCardText,
    BTable,
  },
  setup() {
    return { toast: useToast() };
  },
  data() {
    return {
      loading: false,
      tableColumns: [
        { key: "consultant_id", label: "ID" },
        { key: "consultant_name", label: "Nome", stickyColumn: true },
        { key: "role_name", label: "Nível" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      details: types.DETAILS_NETWORK_DATA,
    }),
  },
  mounted() {
    this.loading = true;
    this.getDetailsNetworkData(this.$route.params.id)
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao obter as a rede do PN. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        this.$router.push({ name: "consultants-list" });
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    ...mapActions({
      getDetailsNetworkData: types.GET_DETAILS_NETWORK_DATA,
    }),
    goToWhatsapp(phone) {
      setTimeout(() => {
        window.open("https://wa.me/" + phone, "_blank").focus();
      });
    },
    goToConsultantDetails(consultant, index) {
      this.$router.push({
        name: "consultant-details-summary",
        params: { id: consultant.consultant_id },
      });
    },
  },
};
</script>
